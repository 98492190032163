.MuiSlider-rail {
  color: #ffffff;
  height: 5pt !important;
  border-radius: 100%;
  border: aliceblue;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
}

.MuiSlider-track {
  height: 7px !important;
  background-color: #0A699E !important;
}

.MuiSlider-thumb {
  width: 25px !important;
  height: 25px !important;
  margin-top: -8px !important;
  margin-left: -12px !important;
  background-color: #FF6700 !important;
}

.MuiSlider-mark {
  background-color: white !important;
}

.MuiSlider-markActive {
  background-color: #0A699E !important;
}


.form-field {
  padding: 8px 5px 8px 40px;
  border-radius: 0;
  border-bottom: 0px solid #e5e5e5;
}
.form-field-selected:active {
  border-radius: 20px;
  border: 5px solid #2BAEB3 !important;
}

.form-field-border-select {
  border-radius: 20px !important;
  border: 5px solid #2BAEB3 !important;
}

#terminos-p {
  text-align: center;
  font-size: 9pt !important;
  margin-right: auto;
}

.progressbar {
  display: block;
  margin: 20px auto;
}

.progressbar div {
  font-size: medium;
  display: inline;
  padding: 8px 13px;
  margin: 20px;
  border-radius: 50%;
}

.inputfile-2:focus+label,
.inputfile-2+label:active {
  color: #2957a1;
}


.inactive {
  background-color: #c5c5c5;
}

.active {
  background-color: #2c8aaf;
  border: 2px solid #fff;
  color: white;
}



.container-top {
  min-height: 40px;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

}

@media only screen and (max-width: 458px) {

  .progressbar {
    display: block;
    margin: 17px auto;
  }

  .progressbar div {
    font-size: small;
    display: inline;
    padding: 6px 10px;
    /*margin: 20px;*/
    border-radius: 50%;
  }

}

@media only screen and (max-width: 370px) {
  .progressbar {
    display: block;
    margin: 10px auto;
  }

  .progressbar div {
    font-size: small;
    display: inline;
    padding: 4px 8px;
    /* margin: 20px;*/
    border-radius: 50%;
  }
}