.MuiSlider-rail {
    color: #ffffff;
    height: 5pt !important;
    border-radius: 100%;
    border: aliceblue;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  }

  .MuiSlider-track {
    height: 7px !important;
    background-color: #0A699E !important;
  }

  .MuiSlider-thumb {
    width: 25px !important;
    height: 25px !important;
    margin-top: -8px !important;
    margin-left: -12px !important;
    background-color: #FF6700 !important;
  } 

  .MuiSlider-mark {
    background-color: white !important;
  }

  .MuiSlider-markActive {
    background-color: #0A699E !important;
  }

  #terminos-p {
    text-align: center;
    font-size: 9pt !important;
    margin-right: auto;
  }